import React from "react";
import Layout from "~/components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Link } from "gatsby";

const TravelAdvisors = ({ data }) => {
	return (
		<Layout>
			<GatsbySeo
				title="Travel Agents"
				description="Discover our bespoke service that sets us apart and learn how we support our travel advisor partners globally."
			/>

			{/* like Hero */}
			<section className="relative bg-white border-b-0 border-gray-900 overflow-hidden">
				<div className="flex flex-col lg:flex-row items-center">
					{/* Left Half */}
					<div className="lg:w-1/2 py-8 px-8 lg:px-0 lg:pr-20 text-center lg:text-left">
						<div className="flex flex-col items-center lg:items-start">
							<h1 className="text-4xl uppercase font-bold tracking-tight text-primary sm:text-6xl">
								Travel Advisors
							</h1>
							<p className="mt-4 text-xl ">
								Supporting our travel advisor partners globally.
							</p>
						</div>
					</div>

					{/* Right Half */}
					<div className="lg:w-1/2 mt-2 lg:mt-0">
						{/* <Image
							className="rounded-lg shadow-md transform border border-gray-200"
							image={data.strapiRegion.image}
							alt={data.strapiRegion.image.name.split("-").join(" ").split(".")[0]}
							loading="lazy"
						/> */}
						<StaticImage
							className="rounded-lg shadow-md transform border border-gray-200"
							src="../images/travel-advisor.jpeg"
							alt="cycling adventures"
							layout="FULL_WIDTH"
							aspectRatio="1.2"
							loading="lazy"
						/>
					</div>
				</div>
			</section>

			{/* Our Story */}
			<section className="mt-28">
				<div className="flex flex-col lg:flex-row items-center lg:justify-between lg:pb-8">
					<div className="lg:w-1/2 lg:pr-8">
						<StaticImage
							className="mx-auto lg:mx-0 lg:w-full rounded-lg shadow-lg"
							src="../images/branded-abt1.jpeg"
							alt="pankaj mangal"
							loading="lazy"
						/>
					</div>
					<div className="lg:w-1/2 lg:pl-6">
						<h2 className="text-2xl font-semibold mb-4 mt-4 lg:mt-0">
							Design a custom bike tour or select a standard itinerary
						</h2>
						<p className="text-gray-700">
							Offer your clients an unparalleled biking adventure with our custom and
							standard bike tours. With custom tours, you can design a bespoke
							experience tailored to their specific interests, fitness levels, and
							schedules, ensuring a personalized and memorable journey. Choose unique
							routes, special activities, and accommodations to meet their preferences.
						</p>
						<br />
						<p className="text-gray-700">
							Alternatively, our standard itineraries provide meticulously planned
							adventures, showcasing the best of each destination. These pre-designed
							routes include handpicked accommodations and curated routes, offering a
							balanced and well-organized experience.
						</p>
						<br />
						<p className="text-gray-700">
							By partnering with us, you can confidently offer your clients a biking
							experience that meets their needs and exceeds their expectations, whether
							they seek a personalized adventure or a hassle-free, pre-planned journey.
						</p>
					</div>
				</div>

				{/* <div className="mt-8">
					<Link
						to="/contact-us/"
						className="bg-white border bottom-1 border-black text-black tracking-wide uppercase px-6 py-2 rounded"
					>
						Get in touch
					</Link>
				</div> */}
			</section>

			{/* Your People. Your Ride. */}
			<section className="my-28">
				<div className="flex flex-col lg:flex-row items-center lg:justify-between lg:pb-8">
					<div className="lg:w-1/2  lg:pr-8">
						<h2 className="text-2xl font-semibold mb-4">Co-Branded Tours</h2>
						<p className="text-gray-700">
							Enhance your agency's prestige with our co-branded bike tours. These
							tours offer a collaborative approach, combining our expertise with your
							agency's unique identity. Co-branded tours allow you to provide exclusive
							experiences that align with your agency's values and appeal, creating
							added value for your clients and strengthening your agency's presence in
							the travel market.
						</p>
					</div>
					<div className="lg:w-1/2 lg:pl-6 mt-4 lg:mt-0">
						<StaticImage
							className="mx-auto lg:mx-0 lg:w-full rounded-lg shadow-lg"
							src="../images/thailand-bike-tour-4.jpeg"
							alt="pankaj mangal"
							loading="lazy"
						/>
					</div>
				</div>
			</section>

			{/* our philosophy */}
			<section className="mb-10">
				<div className="flex flex-col lg:flex-row items-center lg:justify-between lg:pb-8">
					<div className="lg:w-1/2 lg:pr-8">
						<StaticImage
							className="mx-auto lg:mx-0 lg:w-full rounded-lg shadow-lg"
							src="../images/cambodia-kep-cycling2.jpg"
							alt="pankaj mangal"
							loading="lazy"
						/>
					</div>
					<div className="lg:w-1/2 lg:pl-6">
						<h2 className="text-2xl font-semibold mb-4 mt-4 lg:mt-0">Commissions</h2>
						<p className="text-gray-700">
							Partnering with us not only benefits your clients but also rewards you.
							We offer competitive commissions for every booking, ensuring that your
							efforts are well-compensated. This allows you to grow your business while
							providing exceptional cycling experiences.
						</p>
						<br />
					</div>
				</div>
			</section>
			<section className="my-28">
				<div className="flex flex-col lg:flex-row items-center lg:justify-between lg:pb-8">
					<div className="lg:w-1/2  lg:pr-8">
						<h2 className="text-2xl font-semibold mb-4">Training & FAM Trips</h2>
						<p className="text-gray-700">
							We believe in empowering our travel advisor partners with the knowledge
							and firsthand experience needed to sell our tours confidently. We are
							always available for in-person or virtual product training sessions, and
							we offer Familiarization (FAM) trips that allow you to experience our
							bike tours firsthand. These trips provide valuable insights, making it
							easier for you to recommend our tours to your clients.
						</p>
					</div>
					<div className="lg:w-1/2 lg:pl-6 mt-4 lg:mt-0">
						<StaticImage
							className="mx-auto lg:mx-0 lg:w-full rounded-lg shadow-lg"
							src="../images/taiwan-3.jpeg"
							alt="pankaj mangal"
							loading="lazy"
						/>
					</div>
				</div>
			</section>
			{/* <section className="mb-10">
				<div className="flex flex-col lg:flex-row items-center lg:justify-between lg:pb-8">
					<div className="lg:w-1/2 lg:pr-8">
						<StaticImage
							className="mx-auto lg:mx-0 lg:w-full rounded-lg shadow-lg"
							src="../images/cambodia-bike-tour-1.jpeg"
							alt="pankaj mangal"
							loading="lazy"
						/>
					</div>
					<div className="lg:w-1/2 lg:pl-6">
						<h2 className="text-2xl font-semibold mb-4 mt-4 lg:mt-0">
							Join us on tour
						</h2>
						<p className="text-gray-700">
							Experience the exceptional quality of our tours by joining us on tour. We
							invite our travel advisor partners to participate in our bike tours,
							giving you a firsthand understanding of our routes, accommodations, and
							unique offerings. This immersive experience ensures you can authentically
							share the highlights of our tours with your clients.
						</p>
						<br />
					</div>
				</div>
			</section> */}

			<div className="mt-8 mb-20">
				<Link
					to="/contact-us/"
					className="bg-white border-2 bottom-1 border-black text-black tracking-wide uppercase px-6 py-2 rounded"
				>
					Get in touch
				</Link>
			</div>
		</Layout>
	);
};

export default TravelAdvisors;
